import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {DataService} from '../../service/data.service';
import {SlickCarouselComponent} from 'ngx-slick-carousel';
import {environment} from '../../../environments/environment';


declare var $: any;
declare var Sliders: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BaseComponent implements OnInit {
  registerUrl = 'https://duka.digital';
  googlePlayUrl = 'https://play.google.com/store/apps/details?id=br.com.saalve.app';
  appStoreUrl = 'https://apps.apple.com/br/app/saalve/id1599576688';

  facebookUrl = 'https://www.facebook.com/';
  instagramUrl = 'https://www.instagram.com/';
  youtubeUrl = 'https://www.youtube.com/';
  twitterUrl = 'https://twitter.com/';
  linkedinUrl = 'https://br.linkedin.com/';

  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    infinite: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 767.98,
        settings: {}
      }
    ]
  };

  constructor(public router: Router,
              public data: DataService) {
    super(router);
  }

  ngOnInit(): void {
    setTimeout(() => {
      $('.home-image-primary').css('top', '35px');
      $('.home-card-primary').css('width', '100%');
      $('.home-card-secondary').css('width', '100%');
      $('.home-logo').css('left', '8px');
      $('.home-title').css('right', '8px');
      $('.slide').css('right', '8px');
      $('.footer').css('bottom', '0');
    }, 25);
    setTimeout(() => {
      $('.opacity-delay').css('opacity', '1');
    }, 1000);
  }

}
