<div class="container" style="background-color: white !important;">

  <!-- MENU -->
  <div style="margin: 20px;">
    <div style="text-align: center">
      <img src="assets/images/logo.png" alt="logo">
    </div>

    <div class="portlet-body" style="text-align: left; margin-top: 20px;  font-size: 12px">
      <h2 style="text-align: center !important; margin-bottom: 50px;">TERMOS E CONDIÇÕES PARA USO DO APLICATIVO JUCA</h2>

      <p style="margin-bottom: 8px !important;">Por favor, leia com atenção os <b>TERMOS E CONDIÇÕES</b>.</p>

      <p style="margin-top: 0px !important;">
        Ao se cadastrar no aplicativo <b>JUCA</b> ou no site, <b>www.pedeprojuca.com.br</b>,
        você,
        expressamente, <b>DECLARA que ESTÁ DE ACORDO COM AS CONDIÇÕES E TERMOS</b>
        compreendendo todos os <b>TERMOS E CONDIÇÕES</b> do Website. Note que a recusa destes
        <b>Termos do
          Website</b> impedirá que você tenha seu estabelecimento licenciado para uso do
        aplicativo.
      </p>

      <h3 style="margin-top: 50px">PREAMBULO</h3>

      <p>
        O Aplicativo <b>JUCA</b>, juntamente com seu site <b>www.pedeprojuca.com.br</b> -
        compreendem uma
        plataforma digital para oferta de serviços, doravante denominada apenas: <b>Plataforma
        JUCA</b>
        – e somente com a aceitação deste documento e a absoluta concordância com os <b>TERMOS e
        CONDIÇÕES</b> propostos, poderá permitir que você faça pedidos aos restaurantes conveniados ou
        qualquer outro serviço oferecido.
      </p>

      <h3 style="margin-top: 50px">TERMOS DE LICENCIAMENTO</h3>

      <h4 style="margin-top: 25px"><b>1. SERVIÇOS OFERECIDOS</b></h4>
      <ul style="margin-bottom: 50px">
        <li style="margin-bottom: 8px !important;"><b>1.1.</b> A Plataforma <b>JUCA</b> possibilita
          aos <b>RESTAURANTES</b> conveniados e licenciados a exporem seus cardápios e suas condições de
          ofertas de alimentos e bebidas aos <b>USUÁRIOS</b> da Plataforma <b>JUCA</b> os quais, por sua
          vez, podem selecionar suas opções de preferência e encaminhar pedidos aos <b>RESTAURANTES</b>.
        </li>
        <li style="margin-bottom: 8px !important;"><b>1.2.</b> Os serviços disponibilizados pela Plataforma
          <b>JUCA</b> possibilitam a você e a todos os <b>USUÁRIOS</b> cadastrados
          efetivarem, conforme sua escolha dentre as opções apresentadas, solicitações para aquisição de
          alimentos e bebidas fornecidos pelos respectivos <b>RESTAURANTES</b> licenciados na Plataforma
          <b>JUCA</b>, de acordo com os
          respectivos cardápios e condições apresentadas - (entrega em domicílio e/ou retirada no local).
        </li>
        <li style="margin-bottom: 8px !important;"><b>1.3.</b> O serviço da Plataforma <b>JUCA</b> consiste,
          portanto, em aproximar, através do site, os <b>USUÁRIOS</b> e os <b>RESTAURANTES</b>
          cadastrados,
          possibilitando que os <b>USUÁRIOS</b> encaminhem, aos <b>RESTAURANTES</b>, pedidos de entrega de
          gêneros
          alimentícios, bem como, sendo essa a opção dos <b>USUÁRIOS</b>, procederem on-line os pagamentos
          do
          preço dos produtos entregues, diretamente aos <b>RESTAURANTES</b>.
        </li>
        <li style="margin-bottom: 8px !important;"><b>1.4.</b> Desde logo fica esclarecido ao
          <b>USUÁRIO</b> que, por
          sua vez se declara ciente, que a Plataforma <b>JUCA</b>, compreende apenas a intermediação (com
          opção de pagamento on-line direta e exclusivamente ao restaurante) para comercialização de
          produtos alimentícios, sendo que a Plataforma <b>JUCA</b> não é fornecedora de bens, quer sejam
          gêneros
          alimentícios ou de qualquer outra espécie; não presta serviços de transporte; logística ou
          entregas; e que todos esses serviços de fornecimento de alimentos e entregas dos mesmos são
          prestados por fornecedores independentes que não são empregados, sócios, prepostos, nem tampouco
          tem autorização para atuar pela, ou em nome, da Plataforma <b>JUCA</b>. Reconhece, portanto, que
          a
          Plataforma <b>JUCA</b> não tem nenhuma responsabilidade sobre a qualidade; o preparo; a
          embalagem; a
          disponibilização; ou as entregas físicas, via motoboys, ou outros meios, dos alimentos
          adquiridos pelo <b>USUÁRIO</b> dos <b>RESTAURANTES</b>.
        </li>
        <li style="margin-bottom: 8px !important;"><b>1.5. FICA, PORTANTO, O USUÁRIO CIENTE DE QUE TODAS AS
          QUESTÕES ESTIPULADAS EXEMPLIFICATIVAMENTE NO ITEM ANTERIOR E OUTRAS PREVISTAS NO CÓDIGO DE
          DEFESA DO CONSUMIDOR, SÃO DE RESPONSABILIDADE INTEGRAL DO RESTAURANTE, A QUEM DEVERÃO SER
          DIRECIONADOS QUAISQUER RECLAMAÇÕES ACERCA DE PROBLEMAS DECORRENTES DE VÍCIO, DEFEITO DOS
          PRODUTOS, OU FORMA DE SUA ENTREGA, ISENTANDO A PLATAFORMA JUCA DE QUALQUER RESPOSABILIDADE E/OU
          INDENIZAÇÕES ACERCA DOS PRODUTOS ADQUIRIDOS </b>.
        </li>
      </ul>

      <h4 style="margin-top: 50px"><b>2. CADASTRO</b></h4>
      <ul>
        <li style="margin-bottom: 8px !important;"><b>2.1.</b>O <b>USUÁRIO</b>, para utilizar os serviços
          acima descritos, deverá ter capacidade jurídica para atos civis e deverá, necessariamente,
          prestar as informações exigidas no <b>CADASTRO</b>, assumindo integralmente a responsabilidade
          civil e
          criminal pela exatidão e veracidade das informações fornecidas no <b>CADASTRO</b>, que poderá
          ser
          verificado, a qualquer momento, pela Plataforma <b>JUCA</b>.
        </li>
        <li style="margin-bottom: 8px !important;"><b>2.2.</b> O <b>USUÁRIO</b> que deseja utilizar a
          Plataforma
          <b>JUCA</b> precisa obrigatoriamente preencher os campos de cadastro e fornecer informações
          válidas e
          corretas.
        </li>
        <li style="margin-bottom: 8px !important;"><b>2.3.</b> Em caso de informações incorretas,
          inverídicas ou não confirmadas, bem assim na hipótese da negativa em corrigi-las ou enviar
          documentação que comprove a correção, a Plataforma <b>JUCA</b> se reserva o direito de não
          concluir o
          cadastramento em curso ou, ainda, de bloquear o cadastro já existente, impedindo o
          <b>USUÁRIO</b> de
          utilizar os serviços on-line até que, a critério da Plataforma <b>JUCA</b>, a situação de
          anomalia
          esteja regularizada. A Plataforma <b>JUCA</b> se reserva o direito de impedir, a seu critério,
          novos
          cadastros ou cancelar os já efetuados, em caso de ser detectada anomalia que, em sua análise,
          seja revestida de gravidade ou demonstre tentativa deliberada de burlar as regras aqui
          descritas, obrigatórias para todos os <b>USUÁRIOS</b>.
        </li>
        <li style="margin-bottom: 8px !important;"><b>2.4.</b> Também agirá a Plataforma <b>JUCA</b> de tal
          forma
          caso verifique descumprimento, pelo <b>USUÁRIO</b>, de qualquer obrigação prevista no presente
          <b>TERMO</b>.
        </li>
        <li style="margin-bottom: 8px !important;"><b>2.5.</b> Seu número de telefone será confirmado pela
          Plataforma <b>JUCA</b> e, em caso de não confirmação, a utilização do aplicativo será bloqueada
          até que
          ocorra essa confirmação.
        </li>
        <li style="margin-bottom: 8px !important;"><b>2.6.</b> A veracidade das informações do cadastro é de
          exclusiva responsabilidade de quem as inseriu. No caso de acarretarem danos ou prejuízos de
          qualquer espécie, sanções legais podem ser tomadas pela Plataforma <b>JUCA</b> a fim de
          resguardar seus
          interesses e integridade, bem como a dos demais <b>USUÁRIOS</b> e do sistema.
        </li>
      </ul>

      <h4 style="margin-top: 50px"><b>3. OBRIGAÇÕES DO USUÁRIO</b></h4>
      <ul>
        <li style="margin-bottom: 8px !important;"><b>3.1.</b> Efetuado com sucesso seu cadastro de <b>USUÁRIO</b>,
          você assume o compromisso de não compartilhar nem divulgar a terceiros seu login e senha de
          acesso, nem permitir o uso de tais informações por terceiros, responsabilizando-se pelas
          consequências do uso de login e senha de sua titularidade.
        </li>
        <li style="margin-bottom: 8px !important;"><b>3.2.</b> É obrigação do <b>USUÁRIO</b> fornecer
          informações
          cadastrais totalmente verídicas e exatas, responsabilizando-se exclusiva e integralmente, civil
          e criminalmente, por todo o conteúdo por si informado no item CADASTRO, mantendo, de forma
          permanente, na íntegra seus dados atualizados e sempre confirmando o endereço para entrega dos
          produtos encomendados.
        </li>
        <li style="margin-bottom: 8px !important;"><b>3.3.</b> O <b>USUÁRIO</b> se obriga, também, a pagar
          integralmente o preço dos produtos por si solicitados ou encomendados por este site e
          efetivamente a si entregues, diretamente ao <b>RESTAURANTE</b>, seja pela modalidade on-line,
          seja por
          qualquer outra forma aceita pelo <b>RESTAURANTE</b>, ainda que diretamente ao portador dos
          produtos
          entregues.
        </li>
        <li style="margin-bottom: 8px !important;"><b>3.4.</b> O <b>USUÁRIO</b> que seja menor de 18 anos de
          idade
          está ciente de que não poderá encomendar e adquirir, em qualquer hipótese, produtos alcoólicos,
          responsabilizando-se pela correta informação de sua idade no item <b>CADASTRO</b>.
        </li>
        <li style="margin-bottom: 8px !important;"><b>3.5.</b> O <b>USUÁRIO</b> concorda com o uso das
          informações
          de avaliações e feedbacks dos serviços dos <b>RESTAURANTES</b> e da Plataforma <b>JUCA</b>,
          conforme descrito
          nos <b>TERMOS DE PRIVACIDADE da PLATAFORMA JUCA</b>.
        </li>
        <li style="margin-bottom: 8px !important;"><b>3.6.</b> O <b>USUÁRIO</b> se compromete, também, a
          pagar
          integralmente o preço dos produtos por si solicitados ou encomendados, diretamente aos
          <b>RESTAURANTES</b>, no sentido de contribuir com o funcionamento regular e a perpetuação dos
          serviços
          disponibilizados pela Plataforma <b>JUCA</b>.
        </li>
      </ul>

      <h4 style="margin-top: 50px"><b>4. OBRIGAÇÕES DA PLATAFORMA JUCA</b></h4>
      <ul>
        <li style="margin-bottom: 8px !important;"><b>4.1.</b> Disponibilizar, na Plataforma <b>JUCA</b>,
          espaço
          virtual que permita ao <b>USUÁRIO</b> devidamente cadastrado efetivar pedidos de compra de
          gêneros
          alimentícios anunciados e comercializados pelos <b>RESTAURANTES</b> licenciados e também
          disponibilizar
          o pagamento do preço, diretamente ao respectivo <b>RESTAURANTE</b> licenciado, on line, ou sob
          outra
          forma aceita pelo <b>RESTAURANTE</b>.
        </li>
        <li style="margin-bottom: 8px !important;"><b>4.2.</b> Proteger por meio de armazenamento em
          servidores ou quaisquer outros meios magnéticos de alta segurança, a confidencialidade de todas
          as informações e cadastros relativos aos <b>USUÁRIOS</b>, assim como valores atinentes às
          operações
          financeiras advindas da operacionalização dos serviços previstos no presente <b>TERMO</b>.
          Contudo, não
          responderá pela reparação de prejuízos que possam ser derivados de apreensão e cooptação de
          dados por parte de terceiros que, rompendo os sistemas de segurança, consigam acessar essas
          informações.
        </li>
      </ul>

      <h4 style="margin-top: 50px"><b>5. PROPRIEDADE INTELECTUAL</b></h4>
      <ul>
        <li style="margin-bottom: 8px !important;"><b>5.1.</b> As marcas, nomes, logotipos, nomes de domínio
          e demais sinais distintivos, bem como todo e qualquer conteúdo, desenho, arte ou layout
          publicado nos aplicativos, são de propriedade exclusiva da Plataforma <b>JUCA</b>, sendo vedado
          qualquer ato ou contribuição tendente à modificação das características, alteração ou
          incorporação em qualquer outro programa ou sistema.
        </li>
        <li style="margin-bottom: 8px !important;"><b>5.2.</b> Enfim, toda e qualquer forma de reprodução,
          total ou parcial, permanente, temporária ou provisória, de forma gratuita ou onerosa, sob
          quaisquer modalidades, formas ou títulos dos aplicativos é expressamente vedada.
        </li>
      </ul>

      <h4 style="margin-top: 50px"><b>6. OBRIGAÇÕES DA PLATAFORMA JUCA</b></h4>
      <ul>
        <li style="margin-bottom: 8px !important;"><b>6.1.</b> A Plataforma <b>JUCA</b> pode recolher,
          gravar e
          utilizar em conexão com outros sites e parceiros as informações pessoais do <b>USUÁRIO</b>,
          incluindo,
          mas não se limitando a nome, foto, endereço, número de telefone, interesses, atividades, sexo e
          idade.
        </li>
        <li style="margin-bottom: 8px !important;"><b>6.2.</b> A Plataforma <b>JUCA</b> se resguarda no
          direito de
          gravar seu IP, tipo de navegador, aparelho e operadora de celular, posição geográfica e outros
          dados relativos aos pedidos solicitados.
        </li>
        <li style="margin-bottom: 8px !important;"><b>6.3.</b> A Plataforma <b>JUCA</b> não divulga
          publicamente,
          nem a terceiros suas informações de modo que possam identificá-lo, mas pode fazê-lo de forma
          agregada no intuito de realizar análises, estudos, melhoria do sistema e por força de ordem
          judicial.
        </li>
        <li style="margin-bottom: 8px !important;"><b>6.4.</b> Para o recebimento de serviços
          personalizados, o <b>USUÁRIO</b> terá de fornecer informações pessoais (tais como nome e
          endereço
          eletrônico). Nenhuma consequência significativa resultará de sua recusa em apresentar tais
          informações, exceto o fato de que o <b>USUÁRIO</b> não poderá obter a funcionalidade ou receber
          as
          informações que vier a solicitar.
        </li>
        <li style="margin-bottom: 8px !important;"><b>6.5.</b> Ao encaminhar os dados pessoais à Plataforma
          <b>JUCA</b>, o <b>USUÁRIO</b> será tratado como tendo dado sua permissão para o processamento de
          seus dados
          pessoais, conforme estabelecido neste <b>TERMO</b>.
        </li>
        <li style="margin-bottom: 8px !important;"><b>6.6.</b> A Plataforma <b>JUCA</b> poderá utilizar suas
          informações para contatar você com relação a sua opinião a respeito de seus serviços e para
          ocasionalmente notificar você a respeito de alterações ou desenvolvimentos importantes no
          aplicativo ou nos serviços.
        </li>
        <li style="margin-bottom: 8px !important;"><b>6.7.</b> O envio de comentários e feedback com relação
          ao aplicativo e os serviços de delivery dos licenciados da Plataforma <b>JUCA</b>, implica na
          autorização de publicar e utilizar tais comentários e feedback no aplicativo e em quaisquer
          materiais de marketing ou publicidade. A Plataforma <b>JUCA</b> identificará você através de seu
          nome
          de cadastro, foto do perfil da Plataforma <b>JUCA</b> e da sua cidade de residência.
        </li>
        <li style="margin-bottom: 8px !important;"><b>6.8.</b> Os feedbacks sobre os serviços de delivery
          dos <b>RESTAURANTES</b> licenciados também poderão ser compartilhados com estes a fim de
          conhecimento e
          para eventuais contatos dos destes com o <b>USUÁRIO</b> no intuito de solucionar um problema ou
          obter
          uma informação sobre eventual serviço.
        </li>
        <li style="margin-bottom: 8px !important;"><b>6.9.</b> Os comentários e notas atribuídos pelo
          <b>USUÁRIO</b> na avaliação de seu pedido devem conter única e exclusivamente a experiência
          junto ao
          Restaurante Delivery em questão.
        </li>
        <li style="margin-bottom: 8px !important;"><b>6.10.</b> Problemas, críticas, sugestões ou contatos
          com a Plataforma <b>JUCA</b> devem ser direcionados para os canais apropriados do Atendimento ao
          Cliente Plataforma <b>JUCA</b>.
        </li>
        <li style="margin-bottom: 8px !important;"><b>6.11.</b> A Plataforma <b>JUCA</b> se reserva ao
          direito de
          editar ou mesmo excluir os comentários e informações que violem os termos citados nas cláusulas
          acima.
        </li>
        <li style="margin-bottom: 8px !important;"><b>6.12.</b> A Plataforma <b>JUCA</b> fica autorizada a
          proceder
          a coleta de dados de localização exata via aplicativo pelo sistema de permissões utilizado pelo
          sistema operacional do seu dispositivo móvel. Fica esclarecido que você mesmo poderá desativar
          essa permissão posteriormente, alterando as configurações de localização no seu dispositivo
          móvel. No entanto, esta opção limitará a capacidade de uso de certos recursos oferecidos por
          nossos Serviços. Além disso, desabilitar a coleta de dados do aplicativo, referente à
          localização exata do seu dispositivo, não limitará nossa capacidade de coletar informações de
          localização de percurso, via dispositivo de um Condutor, nem a capacidade de deduzir a
          localização aproximada do seu endereço de IP.
        </li>
        <li style="margin-bottom: 8px !important;"><b>6.13.</b> A Plataforma <b>JUCA</b> toma precauções,
          entre
          elas medidas administrativas, técnicas e físicas, para proteger as suas informações pessoais
          contra perda, roubo, uso indevido, bem como contra acesso não autorizado, divulgação, alteração
          e destruição dos seus dados no nosso servidor.
        </li>
        <li style="margin-bottom: 8px !important;"><b>6.14.</b> A Plataforma <b>JUCA</b> somente utilizará
          informações pessoais para os fins que previstos neste <b>TERMO</b>.
        </li>
      </ul>

      <h4 style="margin-top: 50px"><b>7. SANÇÕES</b></h4>
      <ul>
        <li style="margin-bottom: 8px !important;"><b>7.1.</b> O uso da Plataforma <b>JUCA</b> de forma
          indevida em
          desacordo a este <b>TERMO</b> implicará na exclusão do cadastro do <b>USUÁRIO</b> e a proibição
          da utilização
          dos serviços. Seus dados serão preservados para uso das autoridades competentes, caso a
          Plataforma <b>JUCA</b> seja notificada ou acionada pela violação de direitos de terceiros
          decorrente do
          mau uso do sistema.
        </li>
        <li style="margin-bottom: 8px !important;"><b>7.2.</b> O <b>USUÁRIO</b> concorda em indenizar a
          Plataforma
          <b>JUCA</b>, diretores, administradores, colaboradores, representantes e empregados, por
          qualquer
          perda, responsabilização, reclamação ou demanda, por quaisquer prejuízos devidos a ela própria
          ou a terceiros resultantes da utilização indevida da Plataforma <b>JUCA</b>.
        </li>

      </ul>

      <h4 style="margin-top: 50px"><b>8. CONDIÇÕES GERAIS</b></h4>
      <ul>
        <li style="margin-bottom: 8px !important;"><b>8.1.</b> A Plataforma <b>JUCA</b> reserva-se o direito
          de
          alterar o presente <b>TERMO</b> a qualquer tempo. A Política de Privacidade não cria nenhum
          direito
          contratual ou outro direito legal com relação a qualquer parte ou em nome da mesma.
        </li>
        <li style="margin-bottom: 8px !important;"><b>8.2.</b> A presente relação é regida exclusivamente
          pelas leis brasileiras, inclusive eventuais ações decorrentes de violação dos seus termos e
          condições.
        </li>
        <li style="margin-bottom: 8px !important;"><b>8.3.</b> Esta Plataforma pode conter links a outras
          plataformas ou web sites. A presente declaração de privacidade não abrange os referidos sites,
          não podendo a Plataforma <b>JUCA</b> ser responsabilizada pelas práticas de privacidade ou pelo
          conteúdo desses outros sites.
        </li>
      </ul>

      <h4 style="margin-top: 50px"><b>9. MODIFICAÇÕES DESTE TERMO</b></h4>
      <ul>
        <li style="margin-bottom: 8px !important;"><b>9.1.</b> O presente <b>TERMO DE USO</b> poderá, a
          qualquer
          tempo, ter seu conteúdo, ou parte dele, modificados para adequações e inserções, tudo com vistas
          ao aprimoramento dos serviços disponibilizados.
        </li>
        <li style="margin-bottom: 8px !important;"><b>9.2.</b> As novas condições entrarão em vigência assim
          que sua veiculada no site, sendo possível ao <b>USUÁRIO</b> manifestar oposição a quaisquer dos
          termos
          modificados, desde que o faça por escrito, através da Plataforma <b>JUCA</b>, o que gerará o
          cancelamento de seu <b>CADASTRO</b>.
        </li>
      </ul>

      <h4 style="margin-top: 50px"><b>10. CONDIÇÕES GERAIS</b></h4>
      <ul>
        <li style="margin-bottom: 8px !important;"><b>10.1.</b> Para estabelecer contato entre a Plataforma
          <b>JUCA</b> e o <b>USUÁRIO</b> fica disponibilizado o endereço eletrônico <b>www.pedeprojuca.com.br</b>,
          sendo certo
          que o <b>USUÁRIO</b> se obriga, igualmente, a manter em seu cadastro endereço eletrônico atual
          por
          intermédio do qual se farão as comunicações a ele dirigidas pela Plataforma <b>JUCA</b> desde
          logo
          emprestando-se validade jurídica e efetividade a esse meio eletrônico de troca de informações
          recíprocas.
        </li>
      </ul>

      <h4 style="margin-top: 50px"><b>11. ACEITAÇÃO DO TERMO DE USO</b></h4>
      <ul>
        <li style="margin-bottom: 8px !important;"><b>11.1.</b> O <b>USUÁRIO</b> declara ter lido, entendido
          e que
          aceita todas as regras, condições e obrigações estabelecidas no presente <b>TERMO</b>.
        </li>
      </ul>

      <h4 style="margin-top: 50px"><b>12. DO REGISTRO</b></h4>
      <ul>
        <li style="margin-bottom: 8px !important;"><b>12.1.</b> O presente termo se encontra microfilmado
          junto ao Cartório de Registro de Títulos e documentos da Comarca de Atibaia.
        </li>
      </ul>

      <h4 style="margin-top: 50px"><b>13. FORO DE ELEIÇÃO</b></h4>
      <ul>
        <li style="margin-bottom: 50px !important;"><b>13.1.</b> As partes elegem como competente para
          dirimir eventuais controvérsias que venham a surgir da interpretação e do cumprimento do
          presente <b>TERMO</b> o foro da Comarca de Atibaia - SP.
        </li>
      </ul>

    </div>
    <div class="row">
      <div class="col-md-6">
        <input type="checkbox" id="campo-checkbox1"
               [(ngModel)]="flag"/><label
        style="margin-left: 6px">Li e aceito os termos de uso</label>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12" style="text-align: center">
        <button type="button" class="btn btn-primary btn-circle"
                style="width: 150px; height: 50px; margin-top: 14px; margin-right: 20px;">
          Continuar
        </button>
      </div>
    </div>



  </div>

  <!-- MENU END -->
</div>
