import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {DataService} from '../../service/data.service';
import * as svg4everybody from 'svg4everybody/dist/svg4everybody';

declare var $: any;
declare var UI: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent extends BaseComponent implements OnInit {

  constructor(public router: Router,
              public data: DataService) {
    super(router);
  }

  ngOnInit(): void {
    svg4everybody();
  }


}
