<!--<h2>Home</h2>-->
<section class="home">
  <div class="home-container position-relative">
    <div class="home-header">
      <div class="home-logo">
        <img src="assets/images/logo.png" alt="logo">
      </div>
    </div>
  </div>

  <div class="home-card-primary">
    <div class="home-col home-container">
      <div class="home-description opacity-delay">
        <h1>
          Chegou o <span>SAALVE</span>, o App que fala
          a nossa língua.
        </h1>
        <p>Nascido aqui e feito pra nossa gente com o propósito de estimular iniciativas empreendedoras, o comércio
          local, a geração de empregos, além de valorizar o sentido pertencimento à comunidade.</p>
      </div>
      <div class="home-image-primary">
        <img src="assets/images/image-primary.png" alt="logo">
      </div>
      <div class="opacity-delay">
        <div class="slide">
          <a href="{{registerUrl}}" class="btn home-button d-none">
            <span>Cadastre aqui</span>
          </a>
        </div>

      </div>
    </div>
  </div>
  <div class="home-card-secondary">
    <div
      style="column-gap: 60px"
      class="home-container opacity-delay d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
      <h1 class="download-title">
        DÁ UM <span>SAALVE,</span><br> QUE EU TE <span>SAALVO!</span>
      </h1>
      <p class="download-text flex-grow-1"><span>Baixe agora</span><br>Disponível na Google Play e App Store</p>
      <div class="d-flex" style="gap: 17px">
        <a target="_blank" href="{{googlePlayUrl}}" class="download-image">
          <img src="assets/images/btn-download.png" alt="logo">
        </a>
        <a target="_blank" href="{{appStoreUrl}}" class="download-image">
          <img src="assets/images/btn-download-2.png" alt="logo">
        </a>
      </div>

    </div>

  </div>

</section>
<footer class="footer">
  <div class="home-footer">
    <span class="home-footer-copyright">© Copyright 2023 - Saalve</span>
    <div class="home-footer-network">
      <a href="{{facebookUrl}}">
        <svg-icon src="assets/svg/icon-facebook.svg"></svg-icon>
      </a>
      <a href="{{instagramUrl}}">
        <svg-icon src="assets/svg/icon-instagram.svg"></svg-icon>
      </a>
      <a href="{{youtubeUrl}}">
        <svg-icon src="assets/svg/icon-youtube.svg"></svg-icon>
      </a>
      <a href="{{twitterUrl}}">
        <svg-icon src="assets/svg/icon-twitter.svg"></svg-icon>
      </a>
      <a href="{{linkedinUrl}}">
        <svg-icon src="assets/svg/icon-linkedin.svg"></svg-icon>
      </a>
    </div>
  </div>

</footer>
