import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-privacy-policies',
  templateUrl: './privacy-policies.component.html',
  styleUrls: ['./privacy-policies.component.scss']
})
export class PrivacyPoliciesComponent implements OnInit {
  flag = false;

  constructor() {
  }

  ngOnInit(): void {
  }

}
