import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class HeaderHttpInterceptor implements HttpInterceptor {


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let authReq = req;
    const token = localStorage.getItem('token') as string;

    if (token != null) {
      authReq = req.clone(
        {
          headers: req.headers.set('Authorization', 'Bearer ' + token)
            .set('Cache-Control', 'no-cache')
            .set('Pragma', 'no-cache')
        }
      );
    }

    return next.handle(authReq);
  }

}
