import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './views/home/home.component';
import {MainComponent} from './views/main/main.component';
import {DataService} from './service/data.service';
import {UserService} from './service/user.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HeaderHttpInterceptor} from './base/header-interceptor';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import {FormsModule} from '@angular/forms';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {AngularSvgIconModule} from 'angular-svg-icon';
import { TermsOfUseComponent } from './views/terms-of-use/terms-of-use.component';
import { PrivacyPoliciesComponent } from './views/privacy-policies/privacy-policies.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MainComponent,
    TermsOfUseComponent,
    PrivacyPoliciesComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NgxMaskModule.forRoot(),
        FormsModule,
        SlickCarouselModule,
        AngularSvgIconModule.forRoot(),
    ],
  providers: [
    DataService,
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderHttpInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
