import {Injectable} from '@angular/core';
import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class UserService extends ServiceBase {

  constructor(http: HttpClient) {
    super(http);
  }

}
